import React, { useState } from "react";
import "./Contact.css";


function Contact() {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      nonprofitName: "",
      nonprofitLocation: "",
      details: "",
    });
  
    const [showSubmissionMessage, setShowSubmissionMessage] = useState(false);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = (e) => {
        e.preventDefault();
      
        setShowSubmissionMessage(true);
        setTimeout(() => setShowSubmissionMessage(false), 5000);
      
        // Programmatically submit the form
        e.target.submit();
  
      // Reset the form fields 
      setFormData({
        name: "",
        email: "",
        nonprofitName: "",
        nonprofitLocation: "",
        details: "",
      });
  
      // Optionally, set a timeout to hide the submission message after a while
      setTimeout(() => setShowSubmissionMessage(false), 5000);
    }

  return (
    <section>
<iframe name="hidden-form-target" title="Hidden Form Submission Frame" style={{ display: 'none' }}></iframe>
      <h3 className="centered-text">
        Kind Collective is on a quest for causes that resonate with our spirit
        of giving. If you represent a nonprofit in need of a digital boost, or
        know one that is, we're here to lend our creative prowess. Drop us a
        note below and let's amplify the good together!
      </h3>
      <form
        onSubmit={handleSubmit}
        className="contact-form"
        method="POST"
        data-netlify="true"
        name="contact"
        netlify-honeypot="bot-field"
        target="hidden-form-target"
      >
        <input type="hidden" name="form-name" value="contact" />
        <h2>Get in touch</h2>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Name"
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <input
          type="text"
          name="nonprofitName"
          value={formData.nonprofitName}
          onChange={handleChange}
          placeholder="Name of Nonprofit"
          required
        />
        <input
          type="text"
          name="nonprofitLocation"
          value={formData.nonprofitLocation}
          onChange={handleChange}
          placeholder="Location of Nonprofit"
          required
        />
        <textarea
          name="details"
          value={formData.details}
          onChange={handleChange}
          placeholder="Tell us about your initiative and digital needs:"
          required
        />
        <button type="submit">Submit your request!</button>
      </form>
      {showSubmissionMessage && (
        <p className="submission-message">
          Your form has been submitted successfully!
        </p>
      )}
    </section>
  );
}

export default Contact;
