import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import "./Header.css";

export default function Header() {
  const location = useLocation();
  const [selectedNavItem, setSelectedNavItem] = useState('');

  // Update the navigation state when navigating between pages
  useEffect(() => {
    const path = location.pathname;
    let selected = '';
    if (path === '/') selected = 'kind';
    else if (path === '/contact') selected = 'contact';
    else if (path === '/work') selected = 'works';
    setSelectedNavItem(selected);
  }, [location.pathname]);

  return (
    <nav>
      <Link
        to="/"
        id="kind"
        className={`link-button ${selectedNavItem === 'kind' ? 'selected' : ''}`}
        onClick={() => setSelectedNavItem('kind')}
      >
        Kind Collective
      </Link>
      <Link
        to="/contact"
        id="contact"
        className={`link-button ${selectedNavItem === 'contact' ? 'selected' : ''}`}
        onClick={() => setSelectedNavItem('contact')}
      >
        Contact
      </Link>
      <Link
        to="/work"
        id="works"
        className={`link-button ${selectedNavItem === 'works' ? 'selected' : ''}`}
        onClick={() => setSelectedNavItem('works')}
      >
        Works
      </Link>
    </nav>
  );
}
