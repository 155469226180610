import "./Footer.css";

export default function Footer() {
  return (
    <section className="left-content">
      <div id="top-footer">
        <h3>Drop a line to get in touch</h3>
        <h3>
        <a href="mailto:hi@kindcollective.us">hi@kindcollective.us</a>
        </h3>
      </div>
      <div id="bottom-footer">
        <h3>Join us on instagram to see more</h3>
        <div id="with-icon">
          <h3>
            <a href="https://www.instagram.com/kindcollective.us/">
              @kindcollective.us
              <i id="icon" class="fa-brands fa-instagram fa-m"></i>
            </a>
          </h3>
        </div>
      </div>
    </section>
  );
}
