// Work.js

import React from 'react';
// Removed the useNavigate import since it's no longer needed.
import projectDataDetail from '../ProjectDataDetail';
import './Work.css';
import { useState, useEffect } from 'react';

function Work() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const isMobile = windowWidth <= 768; 

  return (
    <div>
      <div className="grid-container">
        <h3 className='works-text'>Dive into our portfolio of accomplishments, where innovation meets impact, and vision becomes reality. This space celebrates not just what we've achieved, but also the enduring relationships and shared values that have been the bedrock of our collaboration.</h3>

        {projectDataDetail.map((project) => (
          <div key={project.id} className="grid-item project-item">
            <h2 className='left'>{project.title}</h2>
            <a href="https://gazasoupkitchen.org" target="_blank" rel="noopener noreferrer">
              <img src={isMobile ? project.imageUrlSmall : project.imageUrlLarge}  alt={`${project.title} preview`} className="centered-image" />
            </a>
            <h3 id="project-description"className='left'>{project.description}</h3>
            <h2 className='left'>More projects coming soon!</h2>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Work;
