// ProjectDataDetail.js

import gazaLargeImage from "../assets/imgs/gazaLargeImage.png";
import gazaSmallImage from "../assets/imgs/gazaSmallImage.png";

const projectDataDetail = [
  {
    id: 'gaza-website',
    title: "Gaza Soup Kitchen's Digital Refresh",
    description:
      "For Gaza Soup Kitchen, we revitalized their digital presence with a complete website redesign that not only enhances user experience but also clearly communicates their commitment to creating a world where every child has access to a hearty meal made with love. The new website is a beacon, rallying supporters and donors around their noble cause with intuitive navigation and compelling storytelling.",
    imageUrlLarge: gazaLargeImage,
    imageUrlSmall: gazaSmallImage,
    liveProjectUrl: "https://gazasoupkitchen.org",
  },
];

export default projectDataDetail;
