import "./MainPg.css";
import { useEffect } from 'react';
import TahiraImage from '../../assets/imgs/tahira.png';
import GermaineImage from '../../assets/imgs/germaine.png';

window.addEventListener('scroll', revealElements);

function revealElements() {
  const reveals = document.querySelectorAll('.reveal');

  for (let i = 0; i < reveals.length; i++) {
    const windowHeight = window.innerHeight;
    const elementTop = reveals[i].getBoundingClientRect().top;
    const elementVisible = 150; // Adjust based on when you want the element to become visible

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add('active');
    } else {
      reveals[i].classList.remove('active');
    }
  }
}

export default function MainPg() {
  useEffect(() => {
    revealElements(); // Call on mount for initial check
    window.addEventListener('scroll', revealElements);
    return () => window.removeEventListener('scroll', revealElements); // Clean up on unmount
  }, []);

  return (
    <section>
      <div className="main-txt">
        <div id="main-txt-left" className="reveal from-left">
          <h1>
            Operating with a dedicated volunteer force, <i>Kind Collective</i>{" "}
            champions those who dedicate themselves to bettering our world.{" "}
          </h1>
        </div>
        <div id="main-txt-right" className="reveal from-right">
          <h1>
            We forge alliances with nonprofits to craft impactful digital
            narratives, from weaving social media journeys to architecting
            website masterpieces. Our core drive is steadfast: to empower those
            who empower others.
          </h1>
        </div>
      </div>
      <div className="left-content reveal">
        <h2>Meet Tahira</h2>
        <img src={TahiraImage} alt="Tahira smiling outside near purple flowers"/>
        <h3>
          Tahira is a Marketing Consultant and Head of Beyond Your Feed Media. Her
          expertise in nurturing and expanding communities is unparalleled,
          making her an invaluable asset to changemakers, initiatives, and
          organizations. With a deep understanding of the nuances of engagement
          and community building, Tahira crafts unique strategies that resonate
          authentically with audiences. Her work is not just about building
          communities; it’s about fostering spaces where ideas flourish and
          meaningful connections are made. Tahira is more than a consultant,
          she's a visionary partner dedicated to turning aspirations into
          tangible successes.
        </h3>
      </div>
      <div className="right-content reveal">
        <h2>Meet Germaine</h2>
        <img src={GermaineImage} alt="Germaine smiling in front of the Petra monastery"/>
        <h3>
          Germaine is a Frontend Developer whose expertise extends beyond
          coding; it's about conveying a compelling digital narrative that
          resonates deeply with users. Germaine's background in user experience
          creates a harmonious blend of aesthetics and functionality, turning
          websites into powerful platforms for change. Nonprofits looking to
          elevate their online presence and maximize their fundraising efforts
          will find in Germaine a strategic innovator.
        </h3>
      </div>
    </section>
  );
}
